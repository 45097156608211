import revive_payload_client_UHZewrtlHz from "/runner/_work/checkout-web/checkout-web/node_modules/.pnpm/nuxt@3.10.2_@parcel+watcher@2.4.1_@types+node@20.11.19_encoding@0.1.13_eslint@8.57.0_optionat_ndohg6vrzalksbm47b6b7urgfe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_owwMbWRDJz from "/runner/_work/checkout-web/checkout-web/node_modules/.pnpm/nuxt@3.10.2_@parcel+watcher@2.4.1_@types+node@20.11.19_encoding@0.1.13_eslint@8.57.0_optionat_ndohg6vrzalksbm47b6b7urgfe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6Qfz6U1gc6 from "/runner/_work/checkout-web/checkout-web/node_modules/.pnpm/nuxt@3.10.2_@parcel+watcher@2.4.1_@types+node@20.11.19_encoding@0.1.13_eslint@8.57.0_optionat_ndohg6vrzalksbm47b6b7urgfe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import check_outdated_build_client_jaJHn2JhmR from "/runner/_work/checkout-web/checkout-web/node_modules/.pnpm/nuxt@3.10.2_@parcel+watcher@2.4.1_@types+node@20.11.19_encoding@0.1.13_eslint@8.57.0_optionat_ndohg6vrzalksbm47b6b7urgfe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_zbacBAXhj0 from "/runner/_work/checkout-web/checkout-web/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import plugin_vue3_YHQ7daOcZR from "/runner/_work/checkout-web/checkout-web/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.12.0_typescript@5.3.3_vue@3.4.19_typescript@5.3.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/runner/_work/checkout-web/checkout-web/.nuxt/components.plugin.mjs";
import prefetch_client_3gwioOeid9 from "/runner/_work/checkout-web/checkout-web/node_modules/.pnpm/nuxt@3.10.2_@parcel+watcher@2.4.1_@types+node@20.11.19_encoding@0.1.13_eslint@8.57.0_optionat_ndohg6vrzalksbm47b6b7urgfe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_mAP6959w1i from "/runner/_work/checkout-web/checkout-web/node_modules/.pnpm/nuxt3-meta-pixel@1.0.9_rollup@4.12.0/node_modules/nuxt3-meta-pixel/dist/runtime/plugin.mjs";
import plugin_zR6KuxZGIZ from "/runner/_work/checkout-web/checkout-web/node_modules/.pnpm/nuxt3-notifications@1.2.0_@nuxt+kit@3.13.2_magicast@0.3.5_rollup@4.12.0_webpack-sources@3.2.3_t42afdsrrtx6lya2z3n4joofqe/node_modules/nuxt3-notifications/dist/runtime/plugin.mjs";
import plugin_client_qzCfqk5yaZ from "/runner/_work/checkout-web/checkout-web/node_modules/.pnpm/nuxt-amplitude-module@1.0.3_rollup@4.12.0/node_modules/nuxt-amplitude-module/dist/runtime/plugin.client.mjs";
import plugin_Ychn70vrDD from "/runner/_work/checkout-web/checkout-web/node_modules/.pnpm/dayjs-nuxt@2.1.9_rollup@4.12.0/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_T7GYDMp7nN from "/runner/_work/checkout-web/checkout-web/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.12.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_nz8Ksc2dxy from "/runner/_work/checkout-web/checkout-web/node_modules/.pnpm/nuxt@3.10.2_@parcel+watcher@2.4.1_@types+node@20.11.19_encoding@0.1.13_eslint@8.57.0_optionat_ndohg6vrzalksbm47b6b7urgfe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_3Zv1Go2tXs from "/runner/_work/checkout-web/checkout-web/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_rollup@4.12.0_typescript@5.3.3_vue@_xkyefzjw5ewxsdcmxkjjsalpb4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import vue_tel_input_jrQu9KYfqt from "/runner/_work/checkout-web/checkout-web/plugins/vue-tel-input.ts";
import vueGoogleMaps_8xmoAwKZvr from "/runner/_work/checkout-web/checkout-web/plugins/vueGoogleMaps.ts";
import xendit_client_cJIjd41DSW from "/runner/_work/checkout-web/checkout-web/plugins/xendit.client.ts";
import openreplay_client_x9gNryAGwo from "/runner/_work/checkout-web/checkout-web/plugins/openreplay.client.ts";
import dompurify_qvTkTTURiH from "/runner/_work/checkout-web/checkout-web/plugins/dompurify.ts";
import browser_visitor_client_ijhVEV8H9L from "/runner/_work/checkout-web/checkout-web/plugins/browser-visitor.client.ts";
import api_GFfDXud5Cr from "/runner/_work/checkout-web/checkout-web/plugins/api.ts";
export default [
  revive_payload_client_UHZewrtlHz,
  unhead_owwMbWRDJz,
  router_6Qfz6U1gc6,
  check_outdated_build_client_jaJHn2JhmR,
  plugin_zbacBAXhj0,
  plugin_vue3_YHQ7daOcZR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_3gwioOeid9,
  plugin_mAP6959w1i,
  plugin_zR6KuxZGIZ,
  plugin_client_qzCfqk5yaZ,
  plugin_Ychn70vrDD,
  plugin_T7GYDMp7nN,
  chunk_reload_client_nz8Ksc2dxy,
  plugin_3Zv1Go2tXs,
  vue_tel_input_jrQu9KYfqt,
  vueGoogleMaps_8xmoAwKZvr,
  xendit_client_cJIjd41DSW,
  openreplay_client_x9gNryAGwo,
  dompurify_qvTkTTURiH,
  browser_visitor_client_ijhVEV8H9L,
  api_GFfDXud5Cr
]