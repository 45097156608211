import { defineStore } from "pinia";
import { useNotification } from "@kyvg/vue3-notification";

export const usePromoStore = defineStore("promo", {
  state: () => ({
    totalPromo: 0,
    totalShippingDiscount: 0,
    totalReward: 0,
    potentialReward: 0,
    promoLoading: false,
    selectedReward: [] as any[],
    appliedReward: [] as any[],
    rewards: [] as any[],
    shippingDiscounts: [] as ShipDiscount[],
    selectedFlikVoucher: [] as any[],
    selectedMerchantVoucher: [] as any[],
    flikVouchers: [] as any[],
    merchantVouchers: [] as any[],
    ppo: null as any,
    voucherCode: "",
  }),
  actions: {
    selectFlikVoucher(voucher: any) {
      console.log("selectFlikVoucher:", voucher);
      const isSelected = this.selectedFlikVoucher.some(
        (v: any) => v.code === voucher.code
      );
      console.log("selectFlikVoucher isSelected:", isSelected);
      if (isSelected) {
        this.removeFlikVoucher(voucher);
      }
      console.log(
        "this.selectedFlikVoucher.length:",
        this.selectedFlikVoucher.length
      );
      if (this.selectedFlikVoucher.length === 1) {
        // replace existing
        this.removeFlikVoucher(this.selectedFlikVoucher[0]);
      }
      this.$patch((state) => {
        state.selectedFlikVoucher.push(voucher);
      });
    },
    removeFlikVoucher(voucher: any) {
      console.log("removeFlikVoucher:", voucher.code);
      this.$patch((state) => {
        state.selectedFlikVoucher = state.selectedFlikVoucher.filter(
          (v: any) => v.code !== voucher.code
        );
      });
    },
    selectMerchantVoucher(voucher: any) {
      console.log("selectMerchantVoucher:", voucher);
      const isSelected = this.selectedMerchantVoucher.some(
        (v: any) => v.code === voucher.code
      );
      console.log("selectMerchantVoucher isSelected:", isSelected);
      if (isSelected) {
        this.removeMerchantVoucher(voucher);
      }
      console.log(
        "selectMerchantVoucher this.selectedMerchantVoucher.length:",
        this.selectedMerchantVoucher.length
      );
      if (this.selectedMerchantVoucher.length > 0) {
        // replace existing
        this.removeMerchantVoucher(this.selectedMerchantVoucher[0]);
      }
      this.$patch((state) => {
        state.selectedMerchantVoucher.push(voucher);
      });
    },
    removeMerchantVoucher(voucher: any) {
      console.log("removeMerchantVoucher:", voucher.code);
      this.$patch((state) => {
        state.selectedMerchantVoucher = state.selectedMerchantVoucher.filter(
          (v: any) => v.code !== voucher.code
        );
      });
    },
    selectReward(rwd: any) {
      console.log("selectReward:", rwd);
      // const isInSelected = this.selectedReward.some(
      //   (r: any) => r.reward_id === rwd.reward_id,
      // );

      // if (!isInSelected) {
      //   this.$patch((state) => {
      //     delete rwd.alert;
      //     state.selectedReward.push(rwd);
      //   });
      // }

      this.removeReward(rwd);

      this.$patch((state) => {
        delete rwd.alert;
        state.selectedReward.push(rwd);
      });
    },
    selectRewardToApply(merchant_id: string) {
      console.log("=> selectRewardToApply:");
      console.log("merchant_id:", merchant_id);

      // remove dangling selectedReward & appliedReward
      const isInApplied = this.appliedReward.find((ar) => {
        return ar.merchant_id !== merchant_id && !ar.is_flik_reward;
      });

      console.log("selectRewardToApply isInApplied:", isInApplied);
      if (isInApplied) {
        this.removeAppliedReward(isInApplied);
      }

      const isInSelected = this.selectedReward.find((sr) => {
        return sr.merchant_id !== merchant_id && !sr.is_flik_reward;
      });

      console.log("selectRewardToApply isInSelected:", isInSelected);
      if (isInSelected) {
        this.removeReward(isInSelected);
      }
      // remove dangling selectedReward & appliedReward

      // reassign appliedReward from selectedReward
      if (this.selectedReward.length > this.appliedReward.length) {
        this.$patch((state) => {
          for (const sr of this.selectedReward) {
            const isInApplied = this.appliedReward.some(
              (r: any) => r.reward_id === sr.reward_id
            );
            if (!isInApplied) {
              state.appliedReward.push(sr);
            }
          }
        });
      }

      if (this.selectedReward.length < this.appliedReward.length) {
        for (const sr of this.selectedReward) {
          const isInApplied = this.appliedReward.some(
            (r: any) => r.reward_id === sr.reward_id
          );
          console.log("isInApplied:", isInApplied);

          if (!isInApplied) {
            this.$patch((state) => {
              state.appliedReward = [];
              state.appliedReward.push(sr);
            });
          }
        }
      }

      this.calcTotalReward();
    },
    removeReward(rwd: any) {
      console.log("removeReward:", rwd.reward_id);
      this.$patch((state) => {
        state.selectedReward = state.selectedReward.filter(
          (r: any) => r.reward_id !== rwd.reward_id
        );
        state.appliedReward = state.appliedReward.filter(
          (r: any) => r.reward_id !== rwd.reward_id
        );
      });
    },
    removeAppliedReward(rwd: any) {
      console.log("removeAppliedReward:", rwd.reward_id);
      this.$patch((state) => {
        state.appliedReward = state.appliedReward.filter(
          (r: any) => r.reward_id !== rwd.reward_id
        );
      });
    },
    calcTotalReward() {
      console.log("=> calcTotalReward:");
      let sumReward = 0;

      for (const sr of this.appliedReward) {
        if (sr?.reward_id) {
          sumReward += sr.applicable_amount;
        }
      }

      this.totalReward = sumReward;
      const cart = useCartStore();
      const shipping = useShippingStore();
      cart.totalOrder = cart.total_amount + shipping.totalAppliedPrice;
      console.log("=> calcTotalReward cart.totalOrder:", cart.totalOrder);
      console.log("=> calcTotalReward: sumReward:", sumReward);
      if (sumReward > cart.totalOrder) cart.totalOrder = 0;
      else cart.totalOrder -= sumReward;
    },
    calcTotalPromo() {
      const cart = useCartStore();
      const shipping = useShippingStore();

      console.log("this.totalReward: ", this.totalReward);
      console.log("cart.total_discount_amount: ", cart.total_discount_amount);
      console.log(
        "shipping.totalAppliedDiscount: ",
        shipping.totalAppliedDiscount
      );

      let sum = cart.total_discount_amount;
      this.totalPromo = sum;

      console.log("calcTotalPromo 1 : ", sum);

      if (this.totalReward) {
        sum += this.totalReward;
      }

      console.log("calcTotalPromo 2 : ", sum);

      if (shipping.totalAppliedDiscount) {
        sum += shipping.totalAppliedDiscount;
      }

      console.log("calcTotalPromo: ", sum);
      this.totalPromo = sum;
    },
    resetReward(mid: string) {
      console.log("=> resetReward: ", mid);
      if (!mid) {
        return false;
      }
      // reset reward for only current merchant_id
      this.$patch((state) => {
        state.appliedReward = state.appliedReward.filter(
          (ar: any) => ar.merchant_id === mid
        );
        state.selectedReward = state.selectedReward.filter(
          (sr: any) => sr.merchant_id === mid
        );
      });
    },
    emptySelectedReward() {
      this.selectedReward = [];
      this.appliedReward = [];
    },
    async postVoucher(vouchers: any[]) {
      this.promoLoading = true;
      const { PROMOSVC } = useRuntimeConfig().public;
      const cart = useCartStore();
      const voucher_codes = vouchers.map((v: any) => v.code);
      const payload = {
        cart_id: cart.cart_id,
        voucher_codes,
      };
      console.log("postVoucher payload:", payload);
      const { data, error } = await useFlikApi<ApiResponse>(
        `${PROMOSVC}/v1/discount`,
        { method: "POST", body: payload }
      );
      const voucherData: any = data?.value?.data || null;
      console.log("voucherData data:", voucherData);
      const voucherErrors = error?.value?.data?.errors || [];
      console.log("voucherErrors error:", voucherErrors);
      if (voucherErrors.length === 0) {
        this.promoLoading = false;
        return true;
      }
      if (voucherErrors.length > 0) {
        useNotification().notify({
          text: voucherErrors[0],
          type: "error",
        });

        this.promoLoading = false;
        return false;
      }
    },
    async deleteVoucher(voucher_code: string) {
      this.promoLoading = true;
      const { PROMOSVC } = useRuntimeConfig().public;
      const cart = useCartStore();
      const payload = {
        cart_id: cart.cart_id,
        voucher_code,
      };
      console.log("deleteVoucher payload:", payload);
      const { data } = await useFlikApi<ApiResponse>(
        `${PROMOSVC}/v1/discount`,
        { method: "DELETE", body: payload }
      );
      const voucherData: any = data?.value?.data || null;
      console.log("voucherData data:", voucherData);
      const voucherErrors = data?.value?.errors || [];
      console.log("voucherErrors error:", voucherErrors);
      if (voucherErrors.length === 0) {
        this.promoLoading = false;
        return true;
      } else {
        this.promoLoading = false;
        return false;
      }
    },
    async getVoucherMerchant(cartId: string) {
      this.promoLoading = true;
      const { PROMOSVC } = useRuntimeConfig().public;

      const { data } = await useFlikApi<ApiResponse>(
        `${PROMOSVC}/v1/discount/cart/${cartId}`
      );
      const voucherData: any = data?.value?.data || null;
      console.log("voucherData data:", voucherData);
      const voucherErrors = data?.value?.errors || [];
      console.log("voucherErrors error:", voucherErrors);
      if (voucherErrors.length === 0) {
        this.$patch((state) => {
          state.flikVouchers = voucherData.flik_vouchers || [];
          state.merchantVouchers = voucherData.merchant_vouchers || [];
        });
        this.promoLoading = false;
        return true;
      }
    },
    async getRewards(mid: string) {
      // block guest shopper for getting reward
      if (useShopperStore().flags.is_guest) {
        return false;
      }

      this.promoLoading = true;

      if (!mid || !useAuthStore().loggedIn) {
        this.promoLoading = false;
        return false;
      }

      const { REWARDSVC } = useRuntimeConfig().public;
      const cart = useCartStore();

      const { data, error } = await useFlikApi<ApiResponse>(
        `${REWARDSVC}/v1/reward-transactions/merchants/${mid}?amount=${cart.totalOrder}`
      );
      const rewardData: any = data?.value?.data || null;
      console.log("getRewards mid:", mid);
      console.log("getRewards rewardData data:", rewardData);
      const rewardErrors = error?.value?.data?.errors || [];
      console.log("rewardErrors error:", rewardErrors);
      if (rewardErrors.length === 0) {
        this.$patch((state) => {
          state.rewards = rewardData || [];
        });
        console.log("getRewards rewardData.length:", rewardData.length);
        if (rewardData.length > 0) {
          for (const rwd of rewardData) {
            console.log("getRewards rwd:", rwd);
            const midInSelected = this.selectedReward.some(
              (sr: any) => sr.merchant_id === mid
            );
            console.log("getRewards midInSelected:", midInSelected);
            const flikRewardIsSelected = this.selectedReward.some(
              (sr: any) => sr.is_flik_reward
            );
            // reset reward if not in selectedReward
            if (!midInSelected && !flikRewardIsSelected) this.resetReward(mid);

            if (!rwd.is_flik_reward && rwd.applicable_amount > 0) {
              this.selectReward(rwd);
              this.selectRewardToApply(mid);
            }
            // if (rwd.applicable_amount === 0) {
            //   this.removeReward(rwd);
            //   this.removeAppliedReward(rwd);
            // }
          }
          this.promoLoading = false;
        }
        this.calcTotalPromo();
        this.promoLoading = false;
        return false;
      }
    },
    async getRewardEstimation(mid: string) {
      // block guest shopper for getting reward
      if (useShopperStore().flags.is_guest) {
        return false;
      }

      this.promoLoading = true;
      const { REWARDSVC } = useRuntimeConfig().public;
      const cart = useCartStore();
      const ship = useShippingStore();
      console.log("getRewardEstimation :", mid);
      console.log("cart.total_amount:", cart.total_amount);
      console.log("ship.totalAppliedPrice:", ship.totalAppliedPrice);
      console.log("this.totalReward:", this.totalReward);

      let totalAmount = 0;

      if (this.totalReward < cart.total_amount) {
        totalAmount = cart.total_amount - this.totalReward;
      }

      console.log("getRewardEstimation cart.total_amount:", cart.total_amount);
      console.log("getRewardEstimation totalAmount:", totalAmount);

      const { data, error } = await useFlikApi<ApiResponse>(
        `${REWARDSVC}/v1/rewards/merchants/${mid}/estimation?amount=${totalAmount}`
      );
      const rewardEstimationData: any = data?.value?.data || null;
      console.log("getRewardEstimation data:", rewardEstimationData);
      const rewardEstimationErrors = error?.value?.data?.errors || [];

      console.log("getRewardEstimation error:", rewardEstimationErrors);
      if (hasLength(rewardEstimationErrors)) {
        console.error("getRewardEstimation error:", rewardEstimationErrors[0]);
        this.promoLoading = false;
        this.potentialReward = 0;
        return false;
      }
      if (rewardEstimationData.reward_estimation >= 0) {
        this.potentialReward = rewardEstimationData.reward_estimation;
        this.promoLoading = false;
        if (rewardEstimationData.reward_estimation > 0) {
          useUiStore().notifPotentialReward = true;
        }
        if (rewardEstimationData.reward_estimation <= 0) {
          useUiStore().notifPotentialReward = false;
        }
        return true;
      }
      useUiStore().notifPotentialReward = false;
      this.promoLoading = false;
    },
    removeShippingDiscount(merchant_id: string) {
      this.shippingDiscounts = this.shippingDiscounts.filter(
        (x: any) => x.mid !== merchant_id
      );
    },
    keepShippingDiscount(merchant_id: string) {
      this.totalShippingDiscount = 0;
      this.shippingDiscounts = this.shippingDiscounts.filter(
        (x: any) => x.merchant_id === merchant_id
      );
      this.calcTotalShippingDiscount();
    },
    calcTotalShippingDiscount() {
      this.totalShippingDiscount = 0;
      for (const shipDisc of this.shippingDiscounts) {
        this.totalShippingDiscount += shipDisc.discount_amount;
        console.log(
          "calcTotalShippingDiscount this.totalShippingDiscount:",
          this.totalShippingDiscount
        );
      }
    },
    resetShippingPromo() {
      this.shippingDiscounts = [];
      this.totalShippingDiscount = 0;
    },
    async getShippingPromo(merchant_id: string, citems?: OrderPromoItem[]) {
      const cart = useCartStore();
      const cItemMerchant = cart.items.find(
        (c: any) => c.merchant_id === merchant_id
      );
      if (cItemMerchant) {
        console.log(`getShippingPromo for ${cItemMerchant.merchant_name}:`);
      }
      this.promoLoading = true;
      if (!useAuthStore().loggedIn) {
        this.promoLoading = false;
        return false;
      }
      const { PROMOSVC } = useRuntimeConfig().public;

      let items: any = [];

      if (citems && hasLength(citems)) {
        items = citems;
      } else {
        for (const item of cart.merchantItemsMid[merchant_id]) {
          items.push({
            ext_id: item.merchant_item_id,
            id: item.item_id,
            qty: item.qty,
          });
        }
      }

      const payload = {
        order: {
          items,
        },
      };
      let mid = merchant_id;
      if (cart.items.length > 0 && merchant_id === "") {
        mid = cart.items[0].merchant_id;
      }
      console.log("getShippingPromo mid:", mid);

      const { data, error } = await useFlikApi<ApiResponse>(
        `${PROMOSVC}/v1/mid/${mid}`,
        { method: "POST", body: payload }
      );
      const promoData: any = data?.value?.data || null;
      console.log("getShippingPromo promoData:", promoData);
      const promoErrors = error?.value?.data?.errors || [];
      console.log("getShippingPromo promoErrors:", promoErrors);

      if (promoData?.length === 0) {
        this.promoLoading = false;
        this.removeShippingDiscount(merchant_id);
        this.calcTotalShippingDiscount();
        // this.calcTotalPromo();
        return false;
      }

      if (promoData && promoErrors.length === 0) {
        if (hasLength(promoData)) {
          for (const shipDisc of promoData) {
            if (cItemMerchant) {
              console.log(
                `getShippingPromo shipDisc for ${cItemMerchant.merchant_name}:`,
                shipDisc
              );
            }
            const isInShipDisc = this.shippingDiscounts.find(
              (sd: any) => sd.mid === merchant_id
            );
            console.log("getShippingPromo isInShipDisc:", isInShipDisc);
            if (isInShipDisc) {
              this.removeShippingDiscount(isInShipDisc.mid);
            }
            if (shipDisc) {
              this.shippingDiscounts.push(shipDisc);
            }
          }
          const makeUnique = [...new Set(this.shippingDiscounts)];
          this.shippingDiscounts = makeUnique;
          console.log(
            `getShippingPromo this.shippingDiscounts:`,
            this.shippingDiscounts
          );
          this.calcTotalShippingDiscount();
          this.promoLoading = false;
          return true;
        }
      }

      if (promoErrors.length > 0) {
        this.appliedReward = [];
        this.selectedReward = [];
        this.promoLoading = false;
        return false;
      }
      this.promoLoading = false;
    },
    async getMultiShippingPromo() {
      this.promoLoading = true;

      const cart = useCartStore();
      const ui = useUiStore();

      if (cart.merchantCount > 0 && ui.shipType === "delivery") {
        for (const [mid, values] of getEntries(cart.merchantItemsMid)) {
          const items: any[] = [];
          for (const item of values) {
            items.push({
              ext_id: item.merchant_item_id,
              id: item.item_id,
              qty: item.qty,
              is_ppo: item.is_ppo,
            });
          }
          console.log("values", values[mid]);
          console.log(`getMultiShippingPromo ${mid} items:`, items);
          await this.getShippingPromo(mid, items);
        }
      }

      this.promoLoading = false;
    },
  },
  persist: [
    {
      key: "nfpromo",
      paths: [
        "selectedReward",
        "appliedReward",
        "lastPromo",
        "lastCourier",
        "lastAddress",
      ],
      storage: persistedState.localStorage,
    },
  ],
});
