import { useNotification } from "@kyvg/vue3-notification";
import { useUiStore } from "./ui";
import { usePPOStore } from "./ppo";

const { notify } = useNotification();

export const useTransactionStore = defineStore("transaction", {
  state: () => ({
    isLoading: false,
    trxId: "",
    lastPayType: "",
    lastTrxStatus: "",
    lastTotal: 0,
    lastPromo: [] as any[],
    lastCourier: [] as PickCourier[],
    lastAddress: null as any,
    lastCartTotalQuantity: 0,
    lastSubTotal: 0,
    lastCartId: "",
    lastCartType: "",
    lastAccount: null as any,
    lastCartItems: [] as any[],
    lastCartRedirectUrl: "",
    vaNumber: "",
    vaExpiry: "",
    vaBank: "",
    qrStr: "",
    qrExpiry: "",
    expiresAt: new Date(),
    payerAuthUrl: "",
    acceptPPO: false,
    listOrder: [] as any,
    totalQty: 0,
    totalShipping: 0,
    totalShippingPromo: 0,
    totalShippingApplied: 0,
  }),
  actions: {
    setTransactionLoading(value: boolean) {
      this.isLoading = value;
    },
    setLastTransaction(pars: any) {
      // set last transaction
      const cart = useCartStore();
      const ship = useShippingStore();
      this.lastCourier = ship.selectedCourier;
      this.lastAddress = pars?.destination?.address_1 || "";
      this.lastSubTotal = cart.subtotal_before_discount;
      this.lastTotal = cart.totalOrder;
      this.lastCartId = cart.cart_id;
      this.lastCartType = cart.type;
      this.lastCartRedirectUrl = cart.option.redirect_url;
      // set last transaction
    },
    async payNow() {
      this.setTransactionLoading(true);
      const ui = useUiStore();
      const shopper = useShopperStore();
      const cart = useCartStore();
      const shipping = useShippingStore();
      const defAddress = shopper.defAddress;
      const payType = shopper.defPayment.type;
      const payment = usePaymentStore();
      const payChild = getChildren(shopper.defPayment?.type, payment.listGroup);

      if (!shopper.hasAccount && !shopper.flags.is_guest) {
        notify({
          text: "Pastikan email dan nama lengkap telah diisi",
          type: "error",
        });
        await navigateTo({ path: "/profile" });
        this.setTransactionLoading(false);
        return;
      }

      console.log("cart.shipment_required", cart.shipment_required);
      console.log("ui.selectedWarehouse:", ui.selectedWarehouse);
      console.log("ui.shipType", ui.shipType);

      if (ui.selectedWarehouse === null && ui.shipType === "pickup") {
        notify({
          text: "Pilih Lokasi Toko",
          type: "error",
        });
        this.setTransactionLoading(false);
        console.error("Pilih Lokasi Toko");
        return;
      }

      if (
        defAddress?.address_id === "" &&
        cart.shipment_required &&
        ui.shipType === "delivery"
      ) {
        notify({
          text: "Pilih Alamat Pengiriman",
          type: "error",
        });
        this.setTransactionLoading(false);
        return;
      }

      if (payType === "") {
        notify({
          text: "Pilih metode pembayaran",
          type: "error",
        });
        this.setTransactionLoading(false);
        return;
      }

      if (!payChild) {
        notify({
          text: "Pilih metode pembayaran",
          type: "error",
        });
        this.setTransactionLoading(false);
        return;
      }

      if (!ui.selectedWarehouse && ui.shipType === "pickup") {
        notify({
          text: "Pilih lokasi toko yang tersedia",
          type: "error",
        });
        this.setTransactionLoading(false);
        return false;
      }

      console.log("cart.items:", cart.items);
      const itemOOS = cart.items.find((item) => item.stock_available !== true);
      console.log("itemOOS:", itemOOS);

      if (itemOOS && ui.shipType === "delivery") {
        notify({
          text: `Produk habis silakan hapus: ${itemOOS.name}`,
          type: "error",
        });
        this.setTransactionLoading(false);
        return false;
      }

      console.log("shipping.countSelected:", shipping.countSelected);
      console.log("cart.merchantCount:", cart.merchantCount);

      if (
        shipping.countSelected !== cart.merchantCount &&
        cart.shipment_required &&
        ui.shipType === "delivery"
      ) {
        notify({
          text: `Pilih kurir untuk setiap merchant`,
          type: "error",
        });
        this.setTransactionLoading(false);
        return false;
      }

      console.log("payNow validation DONE =>");
      console.log("payNow check working hours START =>");
      const workHour = await shipping.checkWorkingHours();
      // stop processing transaction if checkWorkingHours has errors
      if (workHour) return false;
      console.log("payNow check working hours DONE =>");
      const ppo = usePPOStore();

      await ppo.checkPurcacheOffer();
      if (ppo?.product && ppo?.promo) {
        const ppo = usePPOStore();
        ppo.setItems();
        shipping.isLoading = false;
        this.setTransactionLoading(false);
      } else {
        this.continuePay();
      }

      // DO some validations here
    },
    async continuePay() {
      const { CHECKOUT_DOMAIN } = useRuntimeConfig().public;

      this.setTransactionLoading(true);
      console.log("=> continuePay =>");
      const shopper = useShopperStore();
      const cart = useCartStore();
      const ui = useUiStore();
      const payment = usePaymentStore();
      const defAddress = shopper.defAddress;
      const payOneTime = payment.tempPayment;
      console.log("payOneTime:", payOneTime);
      const payType = payOneTime ? payOneTime.id : shopper.defPayment.type;
      console.log("payType:", payType);

      if (payType !== "") this.lastPayType = payType;

      // consturct transaction params
      const trxParams = {
        cart_id: cart.cart_id,
        cart_redirect_url: cart.option.redirect_url || "", // need to send cart_redirect_url
        payment_type_id: payType,
        metadata: {
          store_app_id: cart.metadata.store_app_id,
          redirect_success_url: CHECKOUT_DOMAIN + "/order-success",
          redirect_failed_url: CHECKOUT_DOMAIN + "/order-failed",
          redirect_gopay_url: CHECKOUT_DOMAIN + "/payment",
        },
        destination:
          cart.shipment_required && ui.shipType === "delivery"
            ? defAddress
            : null,
        promotion: [] as any,
        notes: cart.notes,
      };

      // check selected couries
      const shipping = useShippingStore();
      const selcourier =
        shipping.selectedCourier.map((sc: any) => {
          return {
            courier_id: sc.id,
            warehouse_id: sc.warehouse_id,
            shipping_amount: sc.price,
          };
        }) || [];

      if (hasLength(selcourier)) {
        Object.assign(trxParams, {
          shipping: selcourier,
        });
      }
      const ppo = usePPOStore();
      const items: any = [];
      console.log("this.acceptPPO:", this.acceptPPO);
      if (this.acceptPPO) {
        ppo.items.forEach((item: any) => {
          items.push({
            item_id: item.item_id,
            name: item.name,
            qty: item.qty,
            note: item.note,
            is_custom: item.is_custom,
            warehouse_id: item.warehouse_id,
            is_ppo: item.is_ppo,
          });
        });
      } else {
        cart.items.forEach((item: any) => {
          items.push({
            item_id: item.item_id,
            name: item.name,
            qty: item.qty,
            note: item.note,
            is_custom: item.is_custom,
            warehouse_id: item.warehouse_id,
          });
        });
      }

      console.log("cart items:", items);

      if (hasLength(items)) {
        Object.assign(trxParams, {
          items,
        });
      }

      const promo = usePromoStore();
      if (hasLength(promo.appliedReward)) {
        promo.appliedReward.forEach((rwd: any) => {
          trxParams.promotion.push({
            id: rwd.reward_id,
            name: rwd.reward_name,
            mid: rwd.merchant_id,
            type: "reward",
            level: "order",
            total_amount: cart.totalOrder,
            total_discount_amount: rwd.applicable_amount,
          });
        });
      }

      if (hasLength(promo.shippingDiscounts)) {
        promo.shippingDiscounts.forEach((sd: any) => {
          const discCourier = shipping.selectedCourier.find(
            (sc: any) => sc.merchant_id === sd.mid,
          );
          trxParams.promotion.push({
            id: sd.id,
            name: sd.name,
            mid: sd.mid,
            type: "shipping",
            level: "order",
            total_discount_amount: discCourier
              ? discCourier.price_discount_applied
              : 0,
          });
        });
      }

      if (this.acceptPPO && ppo?.promo) {
        trxParams.promotion.push(ppo.promo);
      }

      if (ui.selectedWarehouse && ui.shipType === "pickup") {
        Object.assign(trxParams, {
          shipping: [
            {
              warehouse_id: ui.selectedWarehouse.id,
              courier_id: "pickup",
              shipping_amount: 0,
            },
          ],
        });
      }

      // Card Installment
      console.log("selectedInstallment:", payment.selectedInstallment);
      if (payment.selectedInstallment?.installment?.tenure > 0) {
        Object.assign(trxParams, {
          installment: payment.selectedInstallment.installment,
        });
      }
      // Card Installment

      // consturct transaction params DONE
      // DEBUG
      // -----
      console.log("trxParams:", trxParams);
      // setTimeout(() => {
      //   this.isLoading = false;
      // }, 1000);
      // return;
      // -----
      // End DEBUG

      this.setLastTransaction(trxParams);

      // check payment type condition

      const condQrVA = payType.includes("qr") || payType.includes("virtualacc");

      const condEwallet = ["shopeepay", "dana", "ovo", "gopay"].includes(
        payType,
      );

      console.log("condEwallet:", condEwallet);
      if (condEwallet) {
        await this.createEwalletTrx(trxParams);
      }

      console.log(
        "condQrVA && payOneTime === null:",
        condQrVA && payOneTime === null,
      );
      if (condQrVA && payOneTime === null) {
        await this.createQrVaTrx(trxParams);
      }

      const { APP_DEV } = useRuntimeConfig().public;
      console.log("APP_DEV:", APP_DEV);

      const inPayCard = ["card", "bcadebit"].includes(payType);

      const condCard = !payOneTime && inPayCard;
      const condCardDev = !payOneTime && inPayCard && APP_DEV === "api_dev";

      console.log("condCardDev:", condCardDev);

      if (condCard) {
        // await this.createCardTrx(trxParams);
        await this.createCardAuthTrx(trxParams);
      }

      if (payOneTime && payOneTime.id === "cod") {
        this.createCODTrx(trxParams);
      }
    },
    async createCODTrx(pars: any) {
      this.setTransactionLoading(true);
      console.log("=> createCODTrx isLoading:", this.isLoading);
      console.log("=> createCODTrx params:", pars);
      const trxData = await this.createTransaction(pars);
      // const trxAction = trxData?.payment_info?.actions || null;
      const payType = pars.payment_type_id;
      const payStatus = trxData?.payment_status || "";
      const totalAmount = trxData?.total_amount || 0;

      if (totalAmount) this.lastTotal = totalAmount;
      if (payStatus) this.lastTrxStatus = payStatus;
      if (payType) this.lastPayType = payType;

      console.log("=> createCODTrx payStatus:", payStatus);

      if (payStatus === "FAILED") {
        notify({
          text: "Payment status failed",
          type: "error",
        });
        this.setTransactionLoading(false);
        return;
      }

      if (trxData?.id) {
        this.trxId = trxData.id;
        this.setTransactionLoading(false);

        return navigateTo({ path: `/payment/${trxData.id}` });
      } else {
        console.error("Invalid transaction data");
        this.setTransactionLoading(false);
      }

      this.setTransactionLoading(false);
    },
    async createQrVaTrx(pars: any) {
      this.setTransactionLoading(true);
      console.log("=> createQrVaTrx params:", pars);
      const trxData = await this.createTransaction(pars);
      const trxAction = trxData?.payment_info?.actions || null;
      const payType = pars.payment_type_id;
      const payStatus = trxData?.payment_status || "";
      const totalAmount = trxData?.total_amount || 0;

      if (trxData?.expired_at) this.expiresAt = trxData.expired_at;

      if (totalAmount) this.lastTotal = totalAmount;

      if (payStatus) this.lastTrxStatus = payStatus;

      if (payStatus === "FAILED") {
        notify({
          text: "Payment status failed",
          type: "error",
        });

        this.setTransactionLoading(false);
        return;
      }

      if (trxData?.id) {
        this.trxId = trxData.id;
      } else {
        this.setTransactionLoading(false);
        return;
      }

      if (payType.includes("virtualaccount") && trxAction) {
        this.vaBank = payType.replace("_virtualaccount", "");
        console.log("this.vaBank:", this.vaBank);

        if (this.vaBank === "bsi") {
          this.vaNumber = trxAction.virtual_account_number.slice(4);
        } else {
          this.vaNumber = trxAction.virtual_account_number;
        }

        this.vaExpiry = trxAction?.virtual_account_expiry_time;
        const query = { trxId: trxData?.id };
        amplitudeTrackCheckoutCompleted();
        this.setTransactionLoading(false);
        return navigateTo({ path: "/payment/va", query });
      }
      if (payType.includes("qr") && trxAction) {
        const qrStr = trxAction.qr_checkout_string || "";
        const qrExpiry = trxAction.qr_expiry_time || "";

        this.qrExpiry = qrExpiry;
        this.qrStr = qrStr;
        const query = { trxId: trxData?.id };
        amplitudeTrackCheckoutCompleted();
        this.setTransactionLoading(false);
        return navigateTo({ path: "/payment/qr", query });
      }
    },
    async createEwalletTrx(pars: any) {
      console.log("=> createEwalletTrx =>");

      const shopper = useShopperStore();

      Object.assign(pars, {
        authentication_id: shopper.defPayment.token_id,
      });

      const trData = await this.createTransaction(pars);
      console.log("createEwalletTrx => trData:", trData);

      if (trData?.id) {
        const payStatus = trData?.payment_status || "";
        const payTotal = trData?.total_amount || 0;
        const trxId = trData?.id;

        this.trxId = trxId;

        if (payStatus) this.lastTrxStatus = payStatus;
        if (payTotal) this.lastTotal = payTotal;

        if (
          trData.total_amount === 0 &&
          ["SUCCEEDED", "PENDING"].includes(payStatus)
        ) {
          this.setTransactionLoading(false);
          amplitudeTrackCheckoutCompleted();
          return navigateTo({ path: `/payment/${trData.id}`, replace: true });
        }

        const payActions = trData?.payment_info?.actions || null;

        const { isMobileOrTablet, isDesktop } = useDevice();
        const payType = pars.payment_type_id;
        const gopayDesktop = payType === "gopay" && isDesktop;
        const gopayMobile = payType === "gopay" && isMobileOrTablet;

        const deskWebCheckoutUrl = payActions?.desktop_web_checkout_url || "";
        console.log("deskWebCheckoutUrl:", deskWebCheckoutUrl);
        const mWebCheckoutUrl = payActions?.mobile_web_checkout_url || "";
        console.log("mWebCheckoutUrl:", mWebCheckoutUrl);
        const mDeepLinkUrl = payActions?.mobile_deeplink_checkout_url || "";
        console.log("mDeepLinkUrl:", mDeepLinkUrl);
        const qrStrUrl = payActions?.qr_checkout_url || "";
        console.log("qrStrUrl:", qrStrUrl);

        if (gopayDesktop && hasLength(qrStrUrl)) {
          this.qrStr = qrStrUrl;
          this.qrExpiry = trData?.expired_at;
          this.setTransactionLoading(false);
          const query = { trxId };
          console.log("query:", query);
          amplitudeTrackCheckoutCompleted();
          return navigateTo({ path: "/payment/gopay-qr", query });
        }

        if (gopayMobile && mDeepLinkUrl.length > 0) {
          // Redeem Promotion
          // End redeem Promotion
          this.setTransactionLoading(false);
          amplitudeTrackCheckoutCompleted();
          // DEBUG
          // this.setTransactionLoading(false);
          // return true;
          // DEBUG
          window.open(mDeepLinkUrl, "_self");
          // await navigateTo(mDeepLinkUrl, {
          //   external: true,
          // });
        }

        console.log(
          "hasLength(deskWebCheckoutUrl) && isDesktop:",
          hasLength(deskWebCheckoutUrl) && isDesktop,
        );
        if (hasLength(deskWebCheckoutUrl) && isDesktop) {
          console.log("last transaction", useTransactionStore().trxId);
          this.setTransactionLoading(false);
          amplitudeTrackCheckoutCompleted();
          // DEBUG
          // this.setTransactionLoading(false);
          // return true;
          // DEBUG
          window.open(deskWebCheckoutUrl);
          await navigateTo(deskWebCheckoutUrl, {
            external: true,
          });
        }

        console.log(
          "hasLength(mWebCheckoutUrl) && isMobileOrTablet:",
          hasLength(mWebCheckoutUrl) && isMobileOrTablet,
        );
        if (hasLength(mWebCheckoutUrl) && isMobileOrTablet) {
          this.setTransactionLoading(false);
          amplitudeTrackCheckoutCompleted();
          // DEBUG
          // this.setTransactionLoading(false);
          // return true;
          // DEBUG
          window.open(mWebCheckoutUrl, "_self");
          // await navigateTo(mWebCheckoutUrl, {
          //   external: true,
          // });
        }
      } else {
        this.setTransactionLoading(false);
      }
    },
    async createCardTrx(pars: any) {
      console.log("createCardTrx =>");
      this.setTransactionLoading(true);

      const ct = await this.createTransaction(pars);

      console.log("ct:", ct);
      //  const trxAction = trxData?.payment_info?.actions || null;
      //  const payType = pars.payment_type_id;
      const payStatus = ct?.data?.payment_status || "";
      const totalAmount = ct?.data?.total_amount || 0;

      if (totalAmount) this.lastTotal = totalAmount;

      if (payStatus) this.lastTrxStatus = payStatus;

      if (payStatus === "FAILED") {
        notify({
          text: "Payment status failed",
          type: "error",
        });
        this.setTransactionLoading(false);
        return;
      }

      this.setTransactionLoading(false);
    },
    async createCardAuthTrx(pars: any) {
      console.log("createCardAuthTrx =>");
      this.setTransactionLoading(true);
      const shopper = useShopperStore();
      const cart = useCartStore();

      console.log(
        "createCardAuthTrx shopper.defPayment.token_id:",
        shopper?.defPayment.token_id,
      );

      const ppo = usePPOStore();

      const amount =
        this.acceptPPO && ppo?.promo ? ppo.totalOrder : cart.totalOrder;

      await window.Xendit.card.createAuthentication(
        {
          amount,
          token_id: shopper?.defPayment.token_id || "",
        },
        async (err: any, data: any) => {
          console.log("createCardAuthTrx err:", err);
          console.log("createCardAuthTrx data:", data);
          if (err) {
            // Definisikan penanganan kesalahan
            this.setTransactionLoading(false);
            if (err.error_code === "TOKEN_NOT_FOUND_ERROR") {
              notify({
                text: err.message,
                type: "error",
              });
              return navigateTo({ path: "/payment/card" });
            } else {
              notify({
                text: err.message,
                type: "error",
              });
            }
          }
          if (data.status === "VERIFIED") {
            // Penanganan keberhasilan
            Object.assign(pars, { authentication_id: data.id });
            console.log("prod confirmPay:", pars);
            const ct = await this.createTransaction(pars);
            console.log("ct:", ct);
            if (ct.payment_status === "CAPTURED") {
              this.payerAuthUrl = "";
              amplitudeTrackCheckoutCompleted();
              return navigateTo({ path: `/payment/${ct.id}`, replace: true });
            }
            if (ct.id !== "") {
              this.payerAuthUrl = "";
            }
          } else if (data.status === "IN_REVIEW") {
            // Penanganan otentikasi (3DS)
            this.setTransactionLoading(false);
            this.payerAuthUrl = data.payer_authentication_url;
          } else if (data.status === "FAILED") {
            // Penanganan kegagalan
            notify({
              text: "Card verification FAILED",
              type: "error",
            });
            this.payerAuthUrl = "";
            this.setTransactionLoading(false);
          }
        },
      );

      this.setTransactionLoading(false);
    },
    async createTransaction(pars: any) {
      const { $api: api } = useNuxtApp();
      const {
        data: resp,
        status: reqStatus,
        error,
      } = await api.transaction.postTransaction(pars);
      console.log("createTransaction resp:", resp);
      console.log("createTransaction reqStatus:", reqStatus);
      const trxData: any = resp?.value?.data || null;
      console.log("createTransaction error:", error);
      const trxErrors: any = resp?.value?.data?.errors || [];
      console.log("trxErrors:", trxErrors);
      console.log("trxData:", trxData);

      if (!trxData) {
        console.error("error:", reqStatus);
        this.setTransactionLoading(false);
        return false;
      }

      if (trxData?.id) {
        // empty selected rewards
        usePromoStore().emptySelectedReward();
        const payStatus = trxData?.payment_status || "";
        const payTotal = trxData?.total_amount || 0;

        if (payStatus) this.lastTrxStatus = payStatus;
        if (payTotal) this.lastTotal = payTotal;

        if (payTotal === 0 && ["SUCCEEDED", "PENDING"].includes(payStatus)) {
          this.setTransactionLoading(false);
          amplitudeTrackCheckoutCompleted();
          return navigateTo({ path: `/payment/${trxData.id}`, replace: true });
        }
      }

      if (reqStatus.value === "success" && !hasLength(trxErrors)) {
        return trxData;
      }

      if (reqStatus.value === "success" && hasLength(trxErrors)) {
        this.setTransactionLoading(false);
        const shipping = useShippingStore();
        shipping.isLoading = false;
        shipping.isCalculating = false;
        this.payerAuthUrl = "";
        if (trxErrors[0].includes("unavailable reward")) {
          const promo = usePromoStore();
          const cart = useCartStore();
          promo.appliedReward = [];
          promo.selectedReward = [];
          promo.totalReward = 0;
          promo.getRewards(cart.items[0].merchant_id);
        }
        notify({
          text: trxErrors[0],
          type: "error",
        });
        if (
          trxErrors[0].includes("Payment method provided is not active") ||
          trxErrors[0].includes("payment method is not available")
        ) {
          const payment = usePaymentStore();
          const shopper = useShopperStore();
          await payment.setDefaultPayment({ token_id: "qr_token" });
          await shopper.getShopper();
          // delete later
          const findPayment: any = shopper.saved_payments.find(
            (item: any) => item.type === pars.payment_type_id,
          );
          if (findPayment) await payment.deletePayment(findPayment.token_id);
        }

        // promo code will be deleted from cart
        // get updated cartData
        if (trxErrors[0].includes("promo code [")) {
          // cause Uncaught ReferenceError: Cannot access fetchCartData before initialization
          // await fetchCartData();
          window.location.reload();
        }

        this.setTransactionLoading(false);
        return;
      }
      this.setTransactionLoading(false);
    },
    async getTransactionDetail(id: string) {
      const { $api: api } = useNuxtApp();
      const { data: resp, status: reqStatus } =
        await api.transaction.getDetail(id);

      console.log("getTransactionDetail resp:", resp);
      console.log("getTransactionDetail reqStatus:", reqStatus);
      const data: any = resp?.value?.data || null;
      const errors = resp?.value?.errors || [];
      console.log("getTransactionDetail trxErrors:", errors);
      console.log("getTransactionDetail trxData:", data);

      return { data, errors };
    },
    async getListOrderTransaction(id: string) {
      const { ORDERSVC } = useRuntimeConfig().public;
      const { data, error } = await useFlikApi<any>(
        `${ORDERSVC}/v1/orders/shopper?includes[trx_id]=${id}`,
      );
      const trxData = data.value?.data || null;
      console.log("getListOrderTransaction trxData:", trxData);
      if (trxData && hasLength(trxData)) {
        this.listOrder = trxData;

        let total_qty = 0;
        let total_shipping = 0;
        let total_shipping_promo = 0;
        this.lastCartRedirectUrl = trxData[0].redirect_url;
        this.lastCartItems = trxData[0].items;

        trxData.forEach((order: any) => {
          total_shipping += order.shipping_amount;
          const promoShipping = order?.promo.find(
            (p: any) => p.type === "shipping",
          );
          if (promoShipping) {
            total_shipping_promo += promoShipping.discount_amount;
          }
          order.items.forEach((item: any) => {
            total_qty += item.qty;
          });
        });

        this.totalQty = total_qty;
        this.totalShippingPromo = total_shipping_promo;
        if (total_shipping > total_shipping_promo) {
          this.totalShippingApplied = total_shipping - total_shipping_promo;
          this.totalShipping = total_shipping - total_shipping_promo;
        }
        if (total_shipping === total_shipping_promo) {
          this.totalShipping = 0;
          this.totalShippingApplied = total_shipping_promo;
        }
      }
      const trxErrors = error.value?.data?.errors || null;
      console.log("trxErrors:", trxErrors);
      return { data, error };
    },
  },
  persist: [
    {
      key: "nftrx",
      paths: [
        "trxId",
        "lastTotal",
        "lastAccount",
        "lastCourier",
        "lastCourierTotalAppliedPrice",
        "lastCourierTotalPrice",
        "lastSubTotal",
        "lastPayType",
        "lastCartId",
        "lastCartType",
        "lastCartRedirectUrl",
      ],
      storage: persistedState.cookies,
    },
    {
      key: "nftrx",
      paths: ["lastCartMerchant", "listOrder"],
      storage: persistedState.localStorage,
    },
  ],
});
