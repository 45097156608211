<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
const isDev = process.dev;
useHead({
  // as a string,
  // where `%s` is replaced with the title
  // ... or as a function
  htmlAttrs: {
    lang: "en",
  },
  bodyAttrs: {
    class: isDev ? "debug-screens scrollbar-hide" : "scrollbar-hide",
  },
  titleTemplate: (param) => {
    return param ? `${param} | FLIK` : "";
  },
  link: [
    { rel: "preconnect", href: "https://js.xendit.co" },
    { rel: "dns-prefetch", href: "https://js.xendit.co" },
  ],
});

useSeoMeta({
  title: "FLIK Checkout",
  ogTitle: "FLIK Checkout",
  description: "FLIK 1-click checkout",
  ogDescription: "FLIK 1-click checkout",
  ogImage: "http://placehold.jp/1f1f54/ffffff/300x200.jpg?text=FLIK",
  twitterTitle: "FLIK 1-click checkout",
});

const route = useRoute();
const rName: any = route.name || "";
const pubConf = useRuntimeConfig().public;

const { isDesktopOrTablet } = useDevice();
const ui = useUiStore();
const shopper = useShopperStore();
const address = useAddressStore();
const shipping = useShippingStore();
const payment = usePaymentStore();
const promo = usePromoStore();
const cart = useCartStore();
const defAddress = computed(() => shopper.defAddress || defaultAddress);
const addressList = computed(() => shopper.shipping_address);

const merchantVouchers: any = computed(() => promo.merchantVouchers);
const flikVouchers: any = computed(() => promo.flikVouchers);

const onSearchWarehouse = () => {
  ui.pickupWarehouse = cart.pickup_warehouses.filter((wh: PickupWarehouse) => {
    return wh.warehouse_name
      .toLowerCase()
      .includes(ui.keywordWarehouse.toLowerCase());
  });
};

onMounted(() => {
  watchEffect(() => {
    if (hasLength(cart.pickup_warehouses)) {
      ui.pickupWarehouse = cart.pickup_warehouses;
    }
  });

  if (pubConf.GOOGLE_TAG_MANAGER_ID) {
    const { proxy } = useScriptGoogleTagManager({
      id: pubConf.GOOGLE_TAG_MANAGER_ID,
    });

    useScriptEventPage(() => {
      // triggered on route change after title is updated
      const title = document.title;
      const path = window.location.pathname;
      proxy.dataLayer.push({
        event: "pageview",
        title,
        path,
      });
    });
  }
});
</script>
<template>
  <div id="flikapp" class="relative flex-row scrollbar-hide" :class="[rName]">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <!-- modal pickup warehouse -->
    <LazyModalFlik
      v-if="ui.modalPickupWarehouse"
      :show="ui.modalPickupWarehouse"
      @close="ui.modalPickupWarehouse = false"
    >
      <template #title>
        <h3 class="text-base font-medium text-left">Pilih Lokasi Toko</h3>
      </template>
      <template #info>
        <div
          :class="isDesktopOrTablet ? 'min-w-[280px] ' : ''"
          class="w-full pt-[4px] pb-[16px]"
        >
          <div class="rounded-lg py-3 mb-3 relative">
            <input
              v-model="ui.keywordWarehouse"
              class="flk-input"
              placeholder="Cari lokasi atau nama toko "
              @keyup="onSearchWarehouse"
            />
            <LazyIconsSearch class="absolute top-6 right-4" />
          </div>

          <div
            v-for="(addr, k) in ui.pickupWarehouse"
            :key="k"
            class="w-full flex-row items-center my-4 first:mt-0 last:mb-0 border-b-[1px] pb-4 last:border-none relative"
          >
            <div class="flex justify-between w-full">
              <div class="addrInfo">
                <div class="font-semibold">{{ addr.warehouse_name }}</div>
                <div class="text-[13px]">{{ addr.warehouse_address }}</div>
              </div>
              <input
                type="radio"
                class="flk-radio"
                name="saddress"
                :value="addr.id"
                :checked="addr.id === ui.selectedWarehouse?.id"
                @click="setPickupWarehouse(addr)"
              />
            </div>

            <div
              class="addrAction mt-2.5 flex justify-between items-center gap-3"
            >
              <div class="wrapOperationHour w-9/12">
                <div class="text-neutral-900 text-[13px] font-medium leading-4">
                  Jam Operasional:
                </div>

                <Popover
                  v-slot="{ open }"
                  class="relative -ml-2 cursor-pointer"
                >
                  <PopoverButton
                    class="px-1.5 py-0.5 p-1 flex justify-start gap-1"
                  >
                    <div
                      class="text-[13px] text-gray-400 font-normal flex justify-start gap-1"
                    >
                      <div class="min-w-14">
                        {{ strCapitalized(addr.working_hours[0].day) }}
                      </div>

                      <div>
                        {{ addr.working_hours[0].start_at }} -
                        {{ addr.working_hours[0].end_at }}
                      </div>
                    </div>
                    <LazyIconsChevronDown
                      :class="open ? 'rotate-180 transform' : ''"
                      class="w-5 h-5 text-gray-500"
                    />
                  </PopoverButton>
                  <transition
                    enter-active-class="transition duration-100 ease-out"
                    enter-from-class="transform scale-95 opacity-0"
                    enter-to-class="transform scale-100 opacity-100"
                    leave-active-class="transition duration-75 ease-out"
                    leave-from-class="transform scale-100 opacity-100"
                    leave-to-class="transform scale-95 opacity-0"
                  >
                    <PopoverPanel
                      v-slot="{ close }"
                      class="absolute z-10 p-1 bg-white top-1 left-2 w-auto border border-gray-300"
                    >
                      <div class="list-none font-normal text-gray-400 m-0 p-0">
                        <div
                          v-for="(whour, kw) in addr.working_hours"
                          :key="kw"
                          class="font-normal w-full text-xs p-2 flex justify-start gap-1"
                          @click="close"
                        >
                          <div class="min-w-20">
                            {{ strCapitalized(whour.day) }}
                          </div>
                          <div>{{ whour.start_at }} - {{ whour.end_at }}</div>
                        </div>
                      </div>
                    </PopoverPanel>
                  </transition>
                </Popover>
              </div>
              <a
                :href="addr.google_maps_url"
                target="_blank"
                class="text-blue-700 text-xs font-normal leading-[18px] hover:text-gray-800"
                >Lihat di maps</a
              >
            </div>
          </div>
        </div>
      </template>
    </LazyModalFlik>
    <!-- modal pickup warehouse -->

    <!-- list address -->
    <LazyModalFlik
      v-if="ui.modalAddress"
      :show="ui.modalAddress"
      @close="closeModalAddress"
    >
      <template #title>
        <h3 class="text-base font-medium text-left">Alamat Pengiriman</h3>
      </template>
      <template #info>
        <div
          :class="isDesktopOrTablet ? 'min-w-[280px] ' : ''"
          class="w-full pt-[4px] pb-[40px]"
        >
          <div class="bg-flk-f5 rounded-lg py-3 px-[16px] mb-3">
            <LazyIconsInfo class="inline" />
            <span class="ml-2 xs:text-f13 sm:text-xs"
              >Pilih sebagai alamat pengiriman utama</span
            >
          </div>

          <div
            v-for="(addr, k) in addressList"
            :key="k"
            class="w-full flex-row items-center my-4 first:mt-0 last:mb-0 border-b-[1px] pb-4 last:border-none relative"
          >
            <div class="flex justify-between w-full">
              <div class="addrInfo">
                <div class="font-semibold">{{ addr.address_label }}</div>
                <div>{{ addr.phone }}</div>
                <div class="text-[13px]">{{ addr.address_1 }}</div>
              </div>
              <input
                type="radio"
                class="flk-radio"
                name="saddress"
                :value="addr"
                :checked="addr.address_id === defAddress.address_id"
                @click="setDefaultAddress(addr)"
              />
            </div>

            <div
              class="addrAction mt-2.5 flex justify-start items-center gap-3"
            >
              <button
                class="my-[3px] btn-flk-hollow"
                @click="editAddress(addr)"
              >
                Edit
              </button>

              <Popover v-if="addr.address_id !== defAddress.address_id">
                <PopoverButton
                  class="my-[3px] btn-flk-hollow border-flk-red text-flk-red"
                >
                  <span> Hapus </span>
                </PopoverButton>

                <PopoverPanel
                  v-slot="{ close }"
                  class="absolute border z-[3] p-5 bg-gray-100 w-[calc(100%+16px)] min-h-full top-1/2 -translate-y-1/2 -translate-x-1/2 left-1/2"
                >
                  <div
                    class="text-sm font-bold leading-tight text-center text-gray-900"
                  >
                    Hapus alamat?
                  </div>
                  <div
                    class="text-center text-gray-600 text-sm font-normal leading-tight mb-4 min-w-[190px]"
                  >
                    <p>Yakin ingin hapus alamat ini?</p>
                    <i class="text-xs">{{
                      truncateString(addr.address_1, 60, "...")
                    }}</i>
                  </div>
                  <div
                    class="flex items-center justify-center text-center gap-2"
                  >
                    <button
                      class="my-[3px] btn-flk-hollow border-flk-red text-flk-red"
                      @click="removeAddress(addr.address_id)"
                    >
                      Ya
                    </button>
                    <button
                      class="my-[3px] btn-flk-hollow"
                      @click="hideConfirmDelete(close)"
                    >
                      Tidak
                    </button>
                  </div>
                </PopoverPanel>
              </Popover>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <button
          class="w-full mt-4 btn-flk-dark"
          @click="() => showFormAddressModal()"
        >
          + Tambah Alamat
        </button>
      </template>
    </LazyModalFlik>
    <!-- list address -->

    <!-- form address add/edit -->
    <LazyModalFlik
      v-if="ui.modalFormAddress"
      :show="ui.modalFormAddress"
      @close="closeModalFormAddress"
    >
      <template #title>
        <h3
          v-if="address.toEdit.address_id !== ''"
          class="text-base font-medium text-left"
        >
          Ubah Alamat Pengiriman
        </h3>
        <h3 v-else class="text-base font-medium text-left">
          Tambah Alamat Pengiriman
        </h3>
      </template>
      <template #info>
        <div
          id="modalFormAddress"
          :class="isDesktopOrTablet ? 'min-w-[280px] ' : ''"
          class="w-full pt-[4px] pb-[16px]"
        >
          <div
            v-if="address.toEdit.address_id === ''"
            class="p-4 mb-4 border border-gray-300 rounded-lg bg-neutral-100"
          >
            <LazyIconsInfo class="inline" />
            <span class="ml-2">Mohon lengkapi informasi di bawah ini</span>
          </div>
          <LazyFormAddress
            :addr="address.toEdit"
            :event-name="address.toEdit.address_id !== '' ? 'edit' : 'add'"
          />
        </div>
      </template>
    </LazyModalFlik>
    <!-- form address add/edit -->

    <!-- modal courier -->
    <LazyModalFlik
      v-if="ui.modalCourier && !shipping.isLoading"
      id="modalCourier"
      :show="ui.modalCourier"
      @close="ui.modalCourier = false"
    >
      <template #title>
        <h3 class="text-base font-medium text-left">Pilih Kurir</h3>
      </template>
      <template #info>
        <div
          id="modalContentCourier"
          :class="isDesktopOrTablet ? 'min-w-[280px]' : ''"
          class="w-full h-full"
        >
          <LazyListCourier
            v-if="hasLength(shipping.listCourier)"
            :couriers="shipping.listCourier"
          />
          <div v-else class="text-flk-red animate-pulse my-2">
            Kurir belum tersedia, ganti alamat atau pembayaran
          </div>
        </div>
      </template>
    </LazyModalFlik>
    <!-- modal courier -->

    <!-- modal payment list -->
    <LazyModalFlik
      v-if="ui.modalPayment"
      :show="ui.modalPayment"
      @close="ui.modalPayment = false"
    >
      <template #title>
        <h3 class="text-base font-medium text-left">Pilih Metode Pembayaran</h3>
      </template>
      <template #info>
        <div
          :class="isDesktopOrTablet ? 'min-w-[280px]' : ''"
          class="w-full pt-1 pb-4"
        >
          <LazyListPayment
            v-for="(pg, k) in payment.listGroup"
            :key="k"
            :group="pg"
            @select="selectPayment"
            @nothing="ui.modalPayment = false"
          />
        </div>
      </template>
    </LazyModalFlik>
    <!-- modal payment list -->

    <!-- modal discount -->
    <LazyModalFlik
      v-if="ui.modalDiscount"
      :show="ui.modalDiscount"
      @close="closeModalPromo"
    >
      <template #title>
        <h3 class="text-base font-medium text-left">Diskon</h3>
      </template>
      <template #info>
        <div
          :class="isDesktopOrTablet ? 'min-w-[280px]' : ''"
          class="w-full pt-[4px] pb-[70px]"
        >
          <div class="text-gray-900 text-sm font-medium leading-tight mb-1">
            Punya voucher?
          </div>
          <form class="w-full flex justify-between mb-4 gap-2" @submit.prevent>
            <input
              v-model="promo.voucherCode"
              class="flk-input"
              type="text"
              placeholder="Masukkan kode voucher di sini"
            />

            <button
              v-if="promo.promoLoading"
              class="btn-flk-dark w-full"
              disabled
            >
              <LazyIconsLoadingSpinner class="inline text-white h-4" />
            </button>
            <button
              v-else
              id="applyVoucherCode"
              class="btn-flk-dark"
              @click="
                amplitudeTrackBtnClicked(
                  'Apply Promo Code Button',
                  'Promo Code',
                  '',
                );
                applyVoucherCode(promo.voucherCode);
              "
            >
              Terapkan
            </button>
          </form>

          <div
            v-if="hasLength(cart.discount_lines)"
            class="w-full h-[1px] border-t-[1px] border-gray-200 mb-4"
          ></div>

          <div
            v-if="hasLength(cart.discount_lines)"
            class="text-gray-900 text-sm font-bold leading-tight"
          >
            Voucher Terpakai
          </div>

          <LazyListRemoveableDiscount :discounts="cart.discount_lines" />

          <LazyListShippingDiscount :discounts="promo.shippingDiscounts" />

          <div
            v-if="hasLength(promo.rewards)"
            class="w-full h-[1px] border-t-[1px] border-gray-200 mb-4"
          ></div>

          <div
            v-if="hasLength(promo.rewards)"
            class="text-gray-900 text-sm font-bold leading-tight"
          >
            Reward
          </div>
          <LazyListReward :rewards="promo.rewards" />

          <div
            v-if="hasLength(flikVouchers)"
            class="w-full h-[1px] border-t-[1px] border-gray-200 mb-10"
          ></div>

          <div
            v-if="hasLength(flikVouchers)"
            class="text-gray-900 text-sm font-bold leading-tight"
          >
            FLIK Voucher
          </div>
          <LazyListVoucher
            v-if="hasLength(flikVouchers)"
            :vouchers="flikVouchers"
            vtype="flik"
          />
          <div
            v-if="hasLength(merchantVouchers)"
            class="w-full h-[1px] border-t-[1px] border-gray-200 mb-4"
          ></div>
          <div
            v-if="hasLength(merchantVouchers)"
            class="text-gray-900 text-sm font-bold leading-tight"
          >
            Merchant Voucher
          </div>
          <LazyListVoucher
            v-if="hasLength(merchantVouchers)"
            :vouchers="merchantVouchers"
            vtype="merchant"
          />
        </div>
      </template>
      <template #footer>
        <div class="flex items-center relative min-h-[48px]">
          <button
            v-if="!promo.promoLoading"
            class="btn-flk-dark w-full"
            @click="
              amplitudeTrackBtnClicked('Submit Promo Button', '', '');
              applyPromo();
            "
          >
            Terapkan
          </button>
          <button
            v-if="promo.promoLoading"
            class="btn-flk-dark w-full"
            disabled
          >
            <LazyIconsLoadingSpinner class="inline text-white h-4" />
          </button>
        </div>
      </template>
    </LazyModalFlik>
    <!-- modal discount -->
  </div>
</template>
<style>
html {
  scroll-behavior: smooth;
}
body {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.07px;
  scroll-behavior: smooth;
  /* specific for ios safari */
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
}
#__nuxt {
  @apply h-screen;
}
#flikapp {
  @apply overflow-y-scroll min-h-screen;
}
/* hack blank img */
[data-src] {
  opacity: 0;
  transition: opacity 200ms;
  img {
    opacity: 0;
    transition: opacity 200ms;
  }
}
.lazyLoad.isLoaded,
.lazyLoad[data-src=""],
svg[data-src] {
  opacity: 1;
  img {
    opacity: 1;
  }
}

button:focus,
input:focus,
textarea:focus,
select:focus,
a:focus {
  outline: none;
}

button:focus,
select:focus,
input:focus,
textarea:focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

h1 {
  @apply text-2xl;
}
h2 {
  @apply text-xl;
}

button:focus-visible {
  /* -webkit-box-shadow: 0 0 0px 1000px #fff inset !important; */
  @apply shadow-[0_0_0px_1000px_#fff_inset] !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
}

img {
  @apply text-xs;
}

.vue-notification-group {
  position: static !important;
  z-index: 10 !important;
}
</style>
